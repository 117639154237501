import { To } from "history";
// import { Link } from "react-router-dom";
// import { Typography } from "@mui/material";
// import { APP_DEFAULT_PATH } from "config";
import { SxProps } from "@mui/system";

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <>
    {isIcon ? (
      <img
        style={{ height: "44px", width: "44px" }}
        src={
          "https://anitechgroup.com/au/wp-content/w3-webp/uploads/2023/07/New-logo-1.pngw3.webp"
        }
        alt="nutrition-point logo"
      />
    ) : (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {/* <Typography
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "20px",
            color: "#1677ff",
          }}
          component={Link}
          to={!to ? APP_DEFAULT_PATH : to}
        >
          Store AI
        </Typography> */}
        <img
          style={{ height: "80%", width: "80%" }}
          src={
            "https://anitechgroup.com/au/wp-content/w3-webp/uploads/2023/07/New-logo-1.pngw3.webp"
          }
          alt="nutrition-point logo"
        />
      </div>
    )}
  </>
);

export default LogoSection;
