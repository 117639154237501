import React, { createContext, useEffect, useReducer } from "react";

// third-party
// import { Chance } from 'chance';

// reducer - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import Loader from "components/Loader";
import axios from "utils/axios";
import { AuthProps, JWTContextType } from "types/auth";

// const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken: (st: string) => Promise<boolean> = async (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  console.log("serviceToken", serviceToken);
  try {
    await axios.post("/auth/access-token", { access_token: serviceToken });
    return true;
  } catch (error) {
    console.log("Error while getting the user from service token", error);
    return false;
  }
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        const isTokenValid = await verifyToken(serviceToken || "");
        if (serviceToken && isTokenValid) {
          setSession(serviceToken);
          // const response = await axios.get('/api/account/me');
          // const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              // user
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {
    console.log("url:", process.env.REACT_APP_API_URL);

    const response = await axios.post("/auth/sign-in", {
      email,
      password,
    });
    console.log(response);
    const { access_token, user } = response.data.data;
    setSession(access_token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (email: string, password: string) => {
    console.log("register user");

    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    const response = await axios.post("nutrition-point/api/admin/register", {
      // id,
      email,
      password,
      // firstName,
      // lastName
    });

    console.log(response);

    let users = response.data.user;
    window.localStorage.removeItem("users");

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          // id,
          email,
          password,
          // name: `${firstName} ${lastName}`
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
