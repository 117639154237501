// types
import {
  DefaultConfigProps,
  MenuOrientation,
  ThemeDirection,
  ThemeMode,
} from "types/config";

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = "#1DA1F2";
export const facebookColor = "#3b5998";
export const linkedInColor = "#0e76a8";

export const APP_DEFAULT_PATH = "/chat";
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;
export const CHAT_FLOW_ID = "ec5e9413-8c9b-4dba-b260-a4ea43db15c8";
export const API_HOST = "https://bots.rampwin.com";
export const SYSTEM_MESSAGE_PROMPT =
  "You are the owner of store and have in-depth knowledge about your products. You need to understand customer queries and provide relevant product information based on the context provided, delimited by triple hashtags (###). Your responses should be clear and easy to understand.  \n Here are some guidelines: \n 1. If a customer greets you, respond with a warm welcome and ask for their query. \n 2. If the user asks for link than provide link that is relevant and clickable. \n 3. Always format your answers in simple, plain text with catchy language. \n 4. Use emojis, provide spacing, and most importantly, include links to the products at the end of your description. \n 5. Do not recommend products with titles that have already been mentioned in the conversation history, delimited by triple exclamation marks (!!!). \n 6. Your responses should aim to provide detailed product information based on the customer's query, making use of the context provided.\n context: ###{context}### \n history: !!!{chat_history}!!! \n customer query: ***{question}*** \n Your Answer:";
export const APP_SECRET = process.env.REACT_APP_APP_SECRET;
export const APP_API_URL = process.env.REACT_APP_API_URL;
export const OPENAI_CHAT_MODEL = "gpt-3.5-turbo-1106";
export const OPENAI_API_KEY =
  "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";
// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: "default",
  themeDirection: ThemeDirection.LTR,
};

export default config;
