// third-party
import { FormattedMessage } from "react-intl";

// assets
import { MessageOutlined } from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  MessageOutlined,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: "menu",
  type: "group",
  children: [
    {
      id: "chat",
      title: <FormattedMessage id="chat" />,
      type: "item",
      url: "/chat",
      icon: icons.MessageOutlined,
    },

    // {
    //   id: 'documentation',
    //   title: <FormattedMessage id="documentation" />,
    //   type: 'item',
    //   url: 'https://codedthemes.gitbook.io/mantis/',
    //   icon: icons.QuestionOutlined,
    //   external: true,
    //   target: true,
    //   chip: {
    //     label: 'gitbook',
    //     color: 'secondary',
    //     size: 'small'
    //   }
    // },
    // {
    //   id: 'roadmap',
    //   title: <FormattedMessage id="roadmap" />,
    //   type: 'item',
    //   url: 'https://codedthemes.gitbook.io/mantis/roadmap',
    //   icon: icons.DeploymentUnitOutlined,
    //   external: true,
    //   target: true
    // }
  ],
};

export default other;
