// import { useMemo } from "react";

// material-ui
// import { Theme } from "@mui/material/styles";
import { ListItemIcon, ListItemText } from "@mui/material";

// project import
// import Search from "./Search";
// import Message from "./Message";
// import Profile from "./Profile";
// import Notification from "./Notification";
// import MobileSection from "./MobileSection";
import { ListItemButton } from "@mui/material";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router";
import { LogoutOutlined } from "@mui/icons-material";
// import MegaMenuSection from "./MegaMenuSection";

// import useConfig from "hooks/useConfig";
// import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";

// types
// import { MenuOrientation } from "types/config";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { menuOrientation } = useConfig();

  // const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      logout();
      navigate(`/login`, {
        state: {
          from: "",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
      {/* {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )} */}
      {/* {!downLG && <Search />} */}
      {/* {!downLG && megaMenu} */}
      {/* {downLG && <Box sx={{ width: "100%", ml: 1 }} />} */}

      {/* <Notification />
      <Message /> */}
      {/* {!downLG && <Profile />} */}
      {/* {downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
