import useAuth from "hooks/useAuth";
import { createContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";

export const SocketContext = createContext<Socket | null>(null);

const SocketContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) return;

    const accessToken = localStorage.getItem("serviceToken") as string;
    const socket = io(process.env.REACT_APP_API_URL as string, {
      query: {
        jwt: accessToken,
      },
    });
    socket.on("connect", () => {
      console.log(socket.id, "socket connection established");
    });
    socket.on("disconnect", () => {
      console.log("socket disconnected");
    });
    setSocket(socket);
  }, [isLoggedIn]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketContextProvider;
